<div class="columns">
    <div class="column">
        <app-card
            [name]="cardForm.controls.name.value"
            [cardNumber]="cardForm.controls.cardNumber.value"
            [expiration]="cardForm.controls.expiration.value"

        ></app-card>
    </div>
    <div class="column">
        <form [formGroup]="cardForm" (ngSubmit)="onSubmit()">
            <app-input label="Name" [control]="cardForm.controls.name"></app-input>
            <app-input label="Card Number" [control]="cardForm.controls.cardNumber"></app-input>
            <div class="columns">
                <div class="column">
                    <app-input label="Expiration (MM/DD)" [control]="cardForm.controls.expiration"></app-input>
                </div>
                <div class="column">
                    <app-input label="Security Code" [control]="cardForm.controls.securityCode"></app-input>
                </div>
            </div>
            <div class="buttons">
                <button class="button is-primary" [disabled]="cardForm.invalid" type="submit">Submit</button>
                <button class="button is-warning" type="button" (click)="onResetClick()">Reset</button>
            </div>
        </form>
    </div>
</div>

<!--
<hr>
<div>Form Contents: {{ cardForm.value | json }}</div>
<div>Form is valid: {{ cardForm.valid }}</div>
<div>Errors around name: {{ cardForm.controls.name.errors | json }}</div>
-->