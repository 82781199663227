import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DateFormControl } from '../date-form-control';
import { CreditcardFormControl } from '../creditcard-form-control';

@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.css']
})
export class CardFormComponent implements OnInit {

  cardForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(2)
    ]),
    cardNumber: new CreditcardFormControl('', [
      Validators.required,
      Validators.pattern(/^\d{4}(\s|-)\d{4}(\s|-)\d{4}(\s|-)\d{4}$/)
    ]),
    expiration: new DateFormControl('', [
      Validators.required,
      Validators.pattern(/^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/)
    ]),
    securityCode: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(3)
    ])
  });

  constructor() {
    console.log(this.cardForm.controls.name);
  }

  ngOnInit(): void {
  }

  onSubmit() {
    alert('Good job! ;)')
    console.log('Form was submitted');
  }

  onResetClick() {
    this.cardForm.reset();
  }

}
