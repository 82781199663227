import { FormControl } from '@angular/forms';

export class CreditcardFormControl extends FormControl {
    setValue(value: string | null, options: any) {

        if(!value){
            super.setValue('', { ...options, emitModelToViewChange: true  });
            return; 
        }

        var formattedValue = formatCardNumber(value);
        if(formattedValue){
            super.setValue(formattedValue, { ...options, emitModelToViewChange: true  });
        } else {
            super.setValue(this.value, { ...options, emitModelToViewChange: true  });
        }

    }
}

function formatCardNumber(value) {
    // remove all non digit characters
    var value = value.replace(/\D/g, '');
    var formattedValue;
    var maxLength;
    // american express, 15 digits
    if ((/^3[47]\d{0,13}$/).test(value)) {
      formattedValue = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
      maxLength = 17;
    } else if((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
      formattedValue = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
      maxLength = 16;
    } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
      formattedValue = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
      maxLength = 19;
    }
    
    return formattedValue;
  }